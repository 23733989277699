import { React, useEffect, useState } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
import "./styles.css";

let softPhoneScript;

export default function VectorVirtualAgent() {
  const location = useLocation();

  return (
    <div id="main-content">
      <Outlet />
    </div>
  );
}
