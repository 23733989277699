import { React, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListAltIcon from "@mui/icons-material/ListAlt";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import AppsIcon from "@mui/icons-material/Apps";
import Dialpad from "@mui/icons-material/Dialpad";
import HomeIcon from "@mui/icons-material/Home";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import LogoutIcon from "@mui/icons-material/Logout";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Mic from "@mui/icons-material/Mic";
import AccountBox from "@mui/icons-material/AccountBox";
import DoNotDisturbOnTotalSilenceIcon from "@mui/icons-material/DoNotDisturbOnTotalSilence";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";

export default function Landing() {
  const [value, setValue] = useState(null);
  const [verified, setVerified] = useState(null);
  const [verificationSelected, setVerificationSelected] = useState(null);
  const [verificationType, setVerificationType] = useState(null);
  const [categories, setCategories] = useState(null);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [breadcrumbs, setBreadcrumbs] = useState(null);
  const [formEntryFields, setFormEntryFields] = useState(null);
  const [recordingButtonLabel, setRecordingButtonLabel] = useState(null);
  const [supportCode, setSupportCode] = useState(null);
  const [voiceVerified, setVoiceVerified] = useState(null);
  const [IVRSettings, setIVRSettings] = useState(null);

  const [recordingStatus, setRecordingStatus] = useState(null);

  const last_4_ssn = useRef(null);
  const phone_number = useRef(null);
  const customerForm = useRef(null);
  const audioRef = useRef(null);
  let queryParams = new URLSearchParams(window.location.search);
  let categorySlug = queryParams.get("c") ?? "root";
  let clientUUID = queryParams.get("cid") ?? "root";
  let isTop = false;
  let hasSpoken = false;
  let soundCommitted = false;
  let savedAudioData;
  let mediaRecorder;
  let audioBlobs;
  let currentAudioStream;
  let recordedTime = 0;
  let silenceDetected = false;

  if (categorySlug == "root") {
    isTop = true;
  }

  const detectSilence = (stream, onSoundEnd = (_) => {}, onSoundStart = (_) => {}, silence_delay = 500, min_decibels = -120) => {
    const ctx = new AudioContext();
    const analyser = ctx.createAnalyser();
    const streamNode = ctx.createMediaStreamSource(stream);
    streamNode.connect(analyser);
    analyser.minDecibels = -90;
    analyser.maxDecibels = 0;

    // store audio data
    const audioData = new Uint8Array(analyser.frequencyBinCount);
    let silence_start = performance.now();
    // trigger resets after each occurence

    function loop(time) {
      // check for silence 60x / sec
      if (recordedTime < 600) {
        recordedTime += 1;
      } else {
        if (!soundCommitted) {
          onSoundEnd();
          return;
        }
      }
      requestAnimationFrame(loop);
      analyser.getByteFrequencyData(audioData);
      if (audioData.some((v) => v)) {
        console.log("data");
        // if there is data above the given db limit
        console.log("you should start recording now");
        onSoundStart();
        //visualize();
        // set silence event to now
        silence_start = time;
      }
      if (time - silence_start > silence_delay) {
        if (!silenceDetected) {
          silenceDetected = true;
          console.log("SLIENCES....");
          onSoundEnd();
        }
      }
    }
    loop();
  };

  const onSilence = async () => {
    let audioBlob;
    if (hasSpoken && !soundCommitted) {
      soundCommitted = true;
      console.log("SEND IT");
      console.log("uploading...");

      //
      let mimeType = mediaRecorder.mimeType;

      mediaRecorder.addEventListener("stop", () => {
        audioBlob = new Blob(audioBlobs, { type: mimeType });
        let data = new FormData();

        audioRef.current.src = audioBlob;

        let strSupportCode = makeid(2, "letter") + makeid(2, "number");
        setSupportCode(strSupportCode);

        data.append("supportCode", strSupportCode);
        data.append("wavfile", audioBlob, "recording.wav");

        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        try {
          setRecordingStatus("silence");

          setRecordingButtonLabel("Processing...");
          axios.post("https://falconsnest.dev/id_uploads/upload.php", data, config).then((res) => {
            console.log(res);
            console.log("POSTED");
            console.log("res.data.status=" + res.data.status);
            if (res.data.status == "verified") {
              setVerified(true);
              console.log("VER");
              setVoiceVerified(1);
            } else {
              setVerified(true);
              console.log("UN-VER");
              setVoiceVerified(0);
            }
            saveVerification();
          });
        } catch (ex) {
          console.log(ex);
        }
      });
      mediaRecorder.stop();
      currentAudioStream
        .getTracks() //get all tracks from the stream
        .forEach((track) /*of type MediaStreamTrack*/ => track.stop());
      mediaRecorder = null;
      currentAudioStream = null;
    }
  };

  function onSpeak() {
    if (!soundCommitted) {
      hasSpoken = true;
      console.log("speaking");
      setRecordingStatus("speaking");
    }
  }

  const startRecording = () => {
    setRecordingButtonLabel("Recording...");
    setRecordingStatus("waiting");
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
      })
      .then((stream) => {
        setRecordingStatus("loaded");
        mediaRecorder = new MediaRecorder(stream);
        audioBlobs = [];
        currentAudioStream = stream;
        mediaRecorder.addEventListener("dataavailable", (event) => {
          audioBlobs.push(event.data);
        });
        mediaRecorder.start();
        detectSilence(stream, onSilence, onSpeak, 500, -120);
      })
      .catch((e) => console.log(e));
  };

  const stopRecording = () => {
    console.log("stopButton clicked");
  };

  async function getData() {
    const response = await fetch(process.env.REACT_APP_VIRTUAL_AGENT_API_SERVER_URL + "/api/assist_ivr/subordinates/" + clientUUID + "/" + categorySlug);
    const data = await response.json();
    setCategories(data);
    setCategoriesLoading(false);
    console.log(data);
  }

  async function getSettings() {
    const response = await fetch(process.env.REACT_APP_VIRTUAL_AGENT_API_SERVER_URL + "/api/assist_ivr_settings/" + clientUUID);
    const data = await response.json();
    setIVRSettings(data);
    console.log(data);
  }

  async function saveVerification() {
    let strSupportCode = makeid(2, "letter") + makeid(2, "number");
    setSupportCode(strSupportCode);

    let pn;
    if (phone_number.current) {
      pn = phone_number.current.value;
    }
    let l4;
    if (last_4_ssn.current) {
      l4 = last_4_ssn.current.value;
    }
    const flds = { assist_verification_uuid: "new", support_code: strSupportCode, verification_type: verificationType, phone_number: pn, last_4_ssn: l4, voice_verified: voiceVerified };
    console.log(flds);

    try {
      console.log("GOING");
      const response = await axios.post(process.env.REACT_APP_VIRTUAL_AGENT_API_SERVER_URL + "/api/assist_verifications/save", flds, {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          "Content-Type": "application/json",
        },
      });
      console.log(response);
    } catch (ex) {
      console.log(ex);
    }
  }

  function makeid(length, type) {
    var result = "";
    var characters = "";
    if (type == "letter") {
      characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    } else {
      characters = "0123456789";
    }
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  async function getBreadcrumb() {
    const response = await fetch(process.env.REACT_APP_VIRTUAL_AGENT_API_SERVER_URL + "/api/assist_ivr/path/" + clientUUID + "/" + categorySlug);
    const data = await response.json();
    setBreadcrumbs(data);
    console.log(data);
  }

  const selectVerificationType = (t) => {
    setVerificationSelected(true);
    setVerificationType(t);
  };

  const processForm = () => {
    setVerified(true);
    console.log("SUBMIT");
    saveVerification();
  };

  const resetVerified = () => {
    setVerified(false);
    setRecordingButtonLabel("Begin Verification");
  };

  useEffect(() => {
    console.log("GO");
    setVoiceVerified(0);
    setRecordingButtonLabel("Begin Verification");
    setCategoriesLoading(true);
    getData();
    getBreadcrumb();
    setRecordingStatus("idle");
    getSettings();
  }, []);

  return (
    <>
      <Grid className="virtualAgentContainer" container spacing={0}>
        <Grid item xs={12}>
          {IVRSettings && (
            <>
              <div className="headerRow">
                <h2>{IVRSettings.title}</h2>
                {!verified && <>{IVRSettings.subtitle}</>}
              </div>
            </>
          )}
          {breadcrumbs && !verified ? (
            <>
              <Grid container spacing={2} sx={{ marginLeft: "10px", marginBottom: "10px", paddingTop: "30px" }}>
                {!isTop && (
                  <Button className="homeIconContainer" href={"/landing?cid=" + clientUUID}>
                    <HomeIcon />
                  </Button>
                )}
                {breadcrumbs.map((breadcrumb) => (
                  <Button className="breadcrumb" key={breadcrumb.slug} href={"/landing?c=" + breadcrumb.slug + "&cid=" + clientUUID}>
                    {breadcrumb.label}
                  </Button>
                ))}
              </Grid>
            </>
          ) : (
            <></>
          )}
          {categories && categories.length > 0 ? (
            <Grid container spacing={1} className="categoryContainer">
              {categories.map((category) => (
                <Grid item xs={12} key={category.slug}>
                  <Button variant="outlined" className="categoryButton" href={"/landing?c=" + category.slug + "&cid=" + clientUUID}>
                    {category.label}
                  </Button>
                </Grid>
              ))}
            </Grid>
          ) : (
            <>
              {!categoriesLoading ? (
                <>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <div className="contactForm">
                        {!verified && (
                          <>
                            Before we connect you to an agent, we need to verify your identity.
                            <br />
                            <br />
                          </>
                        )}
                        {!verificationSelected ? (
                          <>
                            <Button onClick={() => selectVerificationType("audio")} variant="outlined" className="btn btn-50" startIcon={<Mic />}>
                              Voice ID
                            </Button>
                            <br />
                            <span className="smaller">Verify by speaking a phrase.</span>
                            <br />
                            <br />
                            <Button onClick={() => selectVerificationType("numeric")} variant="outlined" className="btn btn-50" startIcon={<AccountBox />}>
                              Other Data
                            </Button>
                            <br />
                            <span className="smaller"> Verify with other information.</span>
                          </>
                        ) : (
                          <>
                            {!verified ? (
                              <>
                                {verificationType == "audio" ? (
                                  <>
                                    <span className="smaller">Click the button below to record the phrase</span>
                                    <br />
                                    <div className="passPhrase">My voice is my password.</div>
                                    <br />
                                    <Button onClick={startRecording} variant="outlined" className="recordButton btn-block">
                                      {recordingButtonLabel}
                                    </Button>
                                    <div style={{ paddingTop: "10px" }}>VAD: {recordingStatus}</div>
                                    <br />
                                  </>
                                ) : (
                                  <>
                                    <form ref={customerForm}>
                                      <Grid container spacing={1} className="categoryContainer">
                                        <Grid item xs={12}>
                                          <div className="form-group">
                                            <input type="text" className="form-control" defaultValue={""} name="phone_number" id="phone_number" placeholder="Phone Number" autoFocus={true} ref={phone_number} />
                                          </div>
                                          <div className="form-group">
                                            <input type="password" className="form-control" defaultValue={""} name="ssn_last_4" id="last_4_ssn" placeholder="SSN Last 4" ref={last_4_ssn} />
                                          </div>
                                          <Button onClick={processForm} variant="outlined" className="continueButton btn-block">
                                            Continue
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    </form>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {voiceVerified == 1 || verificationType == "numeric" ? (
                                  <>
                                    <CheckCircleIcon className="verifiedIcon" />
                                    <br />
                                    <span className="verifiedLabel">Verified</span>
                                    <br />
                                    We are here to help you. Click below to call us and we will get you right to an agent that can assist you.
                                    <br />
                                    <br />
                                    {IVRSettings && (
                                      <>
                                        <a variant="outlined" href={"tel:" + IVRSettings.phone_number} className="continueButton btn-block">
                                          Call Now
                                        </a>
                                      </>
                                    )}
                                    <br />
                                    <br />
                                    <b>Your Support Code:</b>
                                    <br />
                                    <span className="supportCode">{supportCode}</span>
                                    <br />
                                    <br />
                                    <br />
                                    <span className="smaller">
                                      Prefer for us to text you? <a href="#">Click here</a>.
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <DoNotDisturbOnTotalSilenceIcon className="unverifiedIcon" />
                                    <br />
                                    <span className="verifiedLabel">Unverified</span>
                                    <br />
                                    Unfortunately, we could not verify your VoiceID, please try again or contact us for assistance.
                                    <br />
                                    <br />
                                    <Button onClick={resetVerified} variant="outlined" className="continueButton btn-block">
                                      Try Again
                                    </Button>
                                    <br />
                                    <br />
                                    Call <b>800-215-1658</b>
                                    <br />
                                    <span className="smaller">
                                      Prefer for us to text you? <a href="#">Click here</a>.
                                    </span>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}{" "}
                        <audio ref={audioRef} />
                      </div>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <div></div>
              )}
            </>
          )}
        </Grid>
        <Grid item xs={3}>
          &nbsp;
        </Grid>
      </Grid>
    </>
  );
}
