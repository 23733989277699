import React from "react";
import PropTypes from "prop-types";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListAltIcon from "@mui/icons-material/ListAlt";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import AppsIcon from "@mui/icons-material/Apps";
import Dialpad from "@mui/icons-material/Dialpad";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import LogoutIcon from "@mui/icons-material/Logout";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { Router, Route, Link } from "react-router-dom";
import { Collapse, Container, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useLocation } from "react-router-dom";
import BusinessIcon from "@mui/icons-material/Business";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import createDOMPurify from "dompurify";
let userLoaded = false;
let objUser;

function Header() {
  const { user, isAuthenticated, isLoading, error, loginWithRedirect, logout } = useAuth0();
  const location = useLocation();

  if (!isLoading && !isAuthenticated) {
    loginWithRedirect({ appState: { targetUrl: "/auth" } });
  } else {
    if (isAuthenticated && !isLoading) {
      objUser = JSON.parse(sessionStorage.getItem("user"));
      //      console.log(objUser);
    }
  }

  const DOMPurify = createDOMPurify(window);

  let navigate = useNavigate();
  const theme = useTheme();

  const drawerWidth = 200;
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleSoftPhoneOpen = () => {
    console.log("disp=" + document.getElementById("softPhonePane").style.display);
    if (document.getElementById("softPhonePane").style.display == "inline") {
      document.getElementById("softPhonePane").style.display = "none";
      document.querySelector("#main-content > div").style.width = document.body.clientWidth - 0 + "px";
    } else {
      document.getElementById("softPhonePane").style.display = "inline";
      document.querySelector("#main-content > div").style.width = document.body.clientWidth - 380 + "px";
    }
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));

  const logoutAndClear = () => {
    sessionStorage.removeItem("user");
    console.log("ClEARED IT");
    logoutWithRedirect();
  };

  const login = () => {
    console.log("LOGIN");
  };

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  console.log(location.pathname);

  return <></>;
}

export default Header;
